export default {
  data() {
    return {
      name: '',
      email: '',
      description: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      latitude: '',
      longitude: '',
      texts: '',
      itemsStates: [],
      screenWidth: 0,
      bLoading: false,
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    this.close();

  },
  beforeMount() {
    this.texts = FILE.vendorTexts[this.selectLanguage];
  },
  methods: {
    editVendors() {
      this.bLoading = true;

      const params = {
        sName: this.name,
        sEmail: this.email,
        sDescription: this.description,
        sPhoneNumber: this.phoneNumber,
        sLocationAddress: this.address,
        sLocationCity: this.city,
        sLocationStateId: this.state,
        sLocationZipCode: this.zipCode,
        dLocationLatitude: this.latitude,
        dLocationLongitude: this.longitude,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.arrEditVendor.sVendorEnterpriseId}`,
        params,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit('refresher', true);
        })
        .catch((error) => {
          this.bLoading = false;
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    close() {
      this.$store.commit('setDialogEditVendor', {
        active: false,
        arr: [],
      });
      this.name = "";
      this.email = "";
      this.description = "";
      this.phoneNumber = "";
      this.address = "";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.latitude = "";
      this.longitude = "";
    },
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    getStates() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.$store.state.arrEditVendor.sCountryId}/states`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {

        },
      })
        .then((response) => {
          this.itemsStates = response.data.results;
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    }
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogEditVendor() {
      return this.$store.state.dialogEditVendor;
    },
    validateForm() {
      return (
        this.name !== "" &&
        this.email !== "" &&
        this.description !== "" &&
        this.phoneNumber !== "" &&
        this.address !== "" &&
        this.city !== "" &&
        this.state !== "" &&
        this.zipCode !== "" &&
        this.latitude !== "" &&
        this.longitude !== "" &&
        this.name !== null &&
        this.email !== null &&
        this.description !== null &&
        this.phoneNumber !== null &&
        this.address !== null &&
        this.city !== null &&
        this.state !== null &&
        this.zipCode !== null &&
        this.latitude !== null &&
        this.longitude !== null
      );
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.vendorTexts[this.selectLanguage];
      }
    },
    dialogEditVendor: function () {
      if (this.dialogEditVendor) {
        this.texts = FILE.vendorTexts[this.selectLanguage];
        this.name = this.$store.state.arrEditVendor.sName;
        this.email = this.$store.state.arrEditVendor.sEmail;
        this.description = this.$store.state.arrEditVendor.sDescription;
        this.phoneNumber = this.$store.state.arrEditVendor.sPhoneNumber;
        this.address = this.$store.state.arrEditVendor.sLocationAddress;
        this.city = this.$store.state.arrEditVendor.sLocationCity;
        this.state = this.$store.state.arrEditVendor.sStateId;
        this.zipCode = this.$store.state.arrEditVendor.sLocationZipCode;
        this.latitude = this.$store.state.arrEditVendor.dLocationLatitude;
        this.longitude = this.$store.state.arrEditVendor.dLocationLongitude;

        this.getStates();
      }
    },
  },
};